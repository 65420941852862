:root {
  font-size: 62.5%;
  /* Primary brand colors */
  --oncourse-blue: #0364D8;
  --support-yellow: #FFE36C;
  --background-gray: #F8F8F8;
  --green: #A6CB95;
  --support-purple: #0D1680;

  /* Additional UI colors */
  --error-red: #D32F2F;
  --text-dark: #333333;
  --text-medium: #666666;
  --text-light: #9E9E9E;
  --border-light: #DDDDDD;
  --border-medium: #E0E0E0;
  --disabled-bg: #F5F5F5;

  /* Opacity variants */
  --blue-light: rgba(3, 100, 216, 0.05);
  --blue-shadow: rgba(3, 100, 216, 0.2);
  --green-light: rgba(166, 203, 149, 0.1);
  --green-shadow: rgba(166, 203, 149, 0.2);
  --yellow-light: rgba(255, 227, 108, 0.1);
  --yellow-shadow: rgba(255, 227, 108, 0.3);
  --purple-light: rgba(13, 22, 128, 0.05);
  --purple-shadow: rgba(13, 22, 128, 0.2);
  --error-light: rgba(211, 47, 47, 0.1);
  --error-shadow: rgba(211, 47, 47, 0.2);

  /* Darker variants for hover states */
  --oncourse-blue-dark: #0252B3;
  --support-purple-dark: #09105E;
  --green-dark: #8AB87A;
  --support-yellow-dark: #E6CA5B;
  --error-red-dark: #B71C1C;

}

h1, h2, h3 {
  font-family: "neusa-next-std-compact", sans-serif;
  font-weight: 700;
  margin: 0;
}

p {
  margin: 0;
}

body {
  font-family: 'Manrope';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1920px;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
